<template>
  <section class="dtc-main-section mt-4" id="">
    <div
      class="dtc-print"
      v-for="(topItem, topIndex) in dtcGridSize"
      :key="topIndex"
      style="page-break-after:always;pointer-events:none;"
    >
      <section class="dtc-print7-grid-header">
        <span
          class="dtc-title"
          v-if="topIndex == 0"
          style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;"
        >
          {{ schoolName }}學校{{ bYear }}學年度{{
            term == 1 ? "上" : "下"
          }}學期偏鄉護理菁英公費生印領清冊核銷明細表
          <b-button
            hidden
            size="sm"
            variant="success"
            @click="save"
            class="mr-2 d-print-none dtc-print-btn2"
            style="display:inline-block;width:100px;height:40px;margin-top:-10px"
          >
            <i class="fas fa-save mr-1"></i>儲存變更
          </b-button>
        </span>

        <div class="dtc-fill-required7">年級</div>
        <div class="dtc-fill-required">姓名</div>

        <section class="sec1">
          <header>
            註冊費
            <small style="font-size : 9px; -webkit-transform : scale(0.75);"
              >註1</small
            >
          </header>
          <div>學雜費</div>
          <div>學生平安保險費</div>
          <div>
            其他相關費用(如語言實習或電腦使用費等，
            <span>請增列或備註說明</span>)
          </div>
          <footer class="pt-3 pl-3">
            依各校及各系所就學所需支付之註冊費用核實支付。
          </footer>
        </section>
        <section class="sec2">
          <header>
            生活費
            <small style="font-size : 9px; -webkit-transform : scale(0.75);"
              >註2</small
            >
          </header>
          <div>膳食費</div>
          <div>零用津貼</div>
          <div>課業費</div>
          <div>書籍費</div>
          <div>制服費</div>
          <div>應屆畢業生旅行參訪費註4</div>
          <footer>
            <div>每名每學期3,250元/月/*6個月=19,500元</div>
            <div>每名每學期3,500元*6個月=21,000元</div>
            <div>每名每學期1,000元</div>
            <div>每名每學期4,000元</div>
            <div>每名每學期2,500元</div>
            <div>每名畢業生3,000元</div>
          </footer>
        </section>
        <section class="sec3">
          <header>
            旅宿費
            <small style="font-size : 9px; -webkit-transform : scale(0.75);"
              >註3</small
            >
          </header>

          <div>
            返鄉旅費
            <small style="font-size : 9px; -webkit-transform : scale(0.75);"
              >註5</small
            >
          </div>
          <div>
            住宿費
            <small style="font-size : 9px; -webkit-transform : scale(0.75);"
              >註6</small
            >
          </div>
          <footer>
            <div>可依第4期計畫每名5,000元或實際狀況預估</div>
            <div>每名每學期3,000元/月/*6個月=18,000元</div>
          </footer>
        </section>
        <div>小計</div>
        <div
          style="border-right:0;writing-mode: vertical-rl; line-height:0px;padding-right:23px;"
        >
          <div style="float:left;transform:translate(-30px, 30px);">
            具領人簽章
          </div>
          (倘直接匯款者得免簽章並應檢附匯款資料)
        </div>
      </section>
      <main
        v-for="(item, idx) in pageRows.slice(
          topIndex * pageSize,
          pageSize + topIndex * pageSize
        )"
        :key="item.myKey"
        class="dtc-table"
      >
        <div class="pt-1">{{ item.year }}</div>

        <div class="pt-1">{{ item.Student }}</div>

        <section class="dtc-s1" style="border:none;">
          <div class="pt-1 text-right-mode">
            {{ $formatPrice(item.learnSpend$) }}
          </div>
          <div class="pt-1 text-right-mode">
            {{ $formatPrice(item.insure$) }}
          </div>
          <div class="pt-1 text-right-mode">
            {{ $formatPrice(item.others$) }}
          </div>
        </section>
        <section class="dtc-s2" style="border:none;">
          <div class="text-right-mode">{{ $formatPrice(item.eat$) }}</div>
          <div class="text-right-mode">{{ $formatPrice(item.allow$) }}</div>
          <div class="text-right-mode">{{ $formatPrice(item.class$) }}</div>
          <div class="text-right-mode">{{ $formatPrice(item.book$) }}</div>
          <div class="text-right-mode">{{ $formatPrice(item.cloth$) }}</div>
          <div class="text-right-mode">{{ $formatPrice(item.travel$) }}</div>
        </section>
        <section class="dtc-s3" style="border:none;">
          <div class="text-right-mode">{{ $formatPrice(item.backHome$) }}</div>
          <div class="text-right-mode">{{ $formatPrice(item.live$) }}</div>
        </section>
        <div style="text-align:right;padding-top:5px;padding-right:5px;">
          {{ $formatPrice(item.rowTotal) }}
        </div>
        <span
          style="border:none;"
          class="dtc-page d-none d-print-none"
          v-if="idx + 1 == pageSize && topIndex + 1 != dtcGridSize"
        >
          {{ `第${topItem}/${dtcGridSize}頁` }}
        </span>
      </main>
      <footer class="dtc-footer-total" v-if="topItem === dtcGridSize">
        <div class="dtx-count">小計</div>
        <section class="dtc-s1" style="border:none;">
          <div style="padding-top:5px;" class="text-right-mode">
            {{ $formatPrice(learnSpendFooter) }}
          </div>
          <div style="padding-top:5px;" class="text-right-mode">
            {{ $formatPrice(insureFooter) }}
          </div>
          <div style="padding-top:5px;" class="text-right-mode">
            {{ $formatPrice(othersFooter) }}
          </div>
        </section>
        <section class="dtc-s2" style="border:none;">
          <div class="text-right-mode">{{ $formatPrice(eatFooter) }}</div>
          <div class="text-right-mode">{{ $formatPrice(allowFooter) }}</div>
          <div class="text-right-mode">{{ $formatPrice(classFooter) }}</div>
          <div class="text-right-mode">{{ $formatPrice(bookFooter) }}</div>
          <div class="text-right-mode">{{ $formatPrice(clothFooter) }}</div>
          <div class="text-right-mode">{{ $formatPrice(travelFooter) }}</div>
        </section>
        <section class="dtc-s3" style="border:none;">
          <div class="text-right-mode">{{ $formatPrice(backHomeFooter) }}</div>
          <div class="text-right-mode">{{ $formatPrice(liveFooter) }}</div>
        </section>
        <div style="padding-top:5px;text-align:right;padding-right:5px;">
          {{ $formatPrice(map.TotalAmount) }}
        </div>
        <div
          style="grid-column: 1/ -1;;border:none;border-top:1px solid var(--dtc-border-color);page-break-after:always;"
        ></div>
        <div
          style="grid-column: 1/ -1;border:none;border-top:1px solid var(--dtc-border-color);padding-top:5px; min-height:180px"
        >
          總計
        </div>
        <div
          style="grid-column: 1/ -1;border:none;border-top:1px solid var(--dtc-border-color)"
          class="dtc-sign-area"
        >
          <div>承辦單位</div>
          <div>註冊組(或學籍稽核單位)</div>
          <div>出納組</div>
          <div>會計(財務)</div>
          <div style="border-right:none;">校長(或授權人)</div>
        </div>
        <div
          style="grid-column: 1/ -1;border:none;border-top:1px solid var(--dtc-border-color); min-height:110px !important"
          class="dtc-sign-area"
        ></div>

        <div
          style="grid-column: 1/ -1;border:none;border-top:1px solid var(--dtc-border-color);"
          class="dtc-note-area"
        >
          <div>【備註】</div>
          <div>
            註1:實報實銷類項目：各款項目請於額度內依各學校實際支付數報核。
          </div>
          <div>註2:定額核銷類項目：每項補助費不得超過該定額補助標準 。</div>
          <div>註3: 在補助額度內類項目：應檢附單據或證明始能補助及核銷。</div>
          <div>註4: 應屆畢業生旅行參訪費：須於公費生畢業當年度報支。</div>
          <div>
            註5:返鄉旅費：應以大眾交通工具並按返鄉必經之順路計算(如住家與學校未超過30公里則不能支領交通費，超過30公里者每學期限補助一次)
            。
          </div>
          <div>
            註6:住宿費：每人每月3,000元，須檢附租賃契約書影本，如為合租無法以學生本人簽訂者，須補附切結書。
          </div>
        </div>
      </footer>
    </div>
  </section>
</template>

<script>
import queryString from "query-string";
const rowObj = {};
let pageRows = new Array(200).fill().map((s, t) => {
  return { myKey: t, Student: "", Remark: "" };
});

export default {
  name: "HelloWorld2212",
  data() {
    return {
      category: [],
      twYears,
      majors: [],
      term: 1,
      bYear: "108",
      map: "",
      schoolName: "",
      oldRowNum: 0,
      dtcGridSize: 1,
      pageRows,
      learnSpendFooter: 0,
      insureFooter: 0,
      othersFooter: 0,
      eatFooter: 0,
      allowFooter: 0,
      classFooter: 0,
      bookFooter: 0,
      clothFooter: 0,
      travelFooter: 0,
      backHomeFooter: 0,
      liveFooter: 0,
      sumFooter: 0,
      pageSize: 10,
    };
  },

  methods: {
    addLearnSpent(item) {
      this.rowPersonUpdate(item);
    },
    rowPersonUpdate(item) {
      if (!item) return;

      this.learnSpendFooter = this.insureFooter = this.othersFooter = 0;
      this.travelFooter = this.clothFooter = this.bookFooter = this.classFooter = this.allowFooter = this.eatFooter = 0;
      this.backHomeFooter = this.liveFooter = 0;
      this.sumFooter = 0;
      this.pageRows.forEach((s) => {
        //////////////////////////////////////////////////
        this.learnSpendFooter +=
          Number(s.learnSpend$) > 0 ? Number(s.learnSpend$) : 0;
        this.insureFooter += Number(s.insure$) > 0 ? Number(s.insure$) : 0;
        this.othersFooter += Number(s.others$) > 0 ? Number(s.others$) : 0;
        ////////
        this.eatFooter += s.eat$ > 0 ? s.eat$ : 0;
        this.allowFooter += s.allow$ > 0 ? s.allow$ : 0;
        this.classFooter += s.class$ > 0 ? s.class$ : 0;
        this.bookFooter += s.book$ > 0 ? s.book$ : 0;
        this.clothFooter += s.cloth$ > 0 ? s.cloth$ : 0;
        this.travelFooter += s.travel$ > 0 ? s.travel$ : 0;
        /////
        this.backHomeFooter += s.backHome$ > 0 ? s.backHome$ : 0;
        this.liveFooter += s.live$ > 0 ? s.live$ : 0;
        //
        this.sumFooter += s.rowTotal > 0 ? s.rowTotal : 0;
      });
    },
    async save() {
      try {
        this.deNormalizeData();
        await window.axios.put(
          "Budgeting/PutList?id=" + this.map.SeqNo,
          this.map
        );
        this.$root.$emit("dtc-save-form-ok");
      } catch (e) {}
    },
    deNormalizeData() {
      this.map.temp_detail.forEach((s, i) => {
        s.Student = this.pageRows[i].Student;
      });
    },
    normalizeData(map) {
      let bugs = map.temp_detail;
      this.pageSize = map.temp_detail.length;
      this.oldRowNum = bugs.length;
      let c = 0;
      bugs.forEach((s, i) => {
        let item = this.pageRows[i];
        item.Remark = s.Remark;
        item.Student = s.Student;
        item.Bno = s.Bno;
        item.where = s.MajorId;
        let cat = this.category.find((s) => s.value == item.where);
        if (cat) {
          item.whereTip = this.category.find((s) => s.value == item.where).text;
        } else {
          item.whereTip = "暫無資料";
          ++c;
          // testing
        }
        item.year = s.Grade;
        item.persons = s.NumberOfPeople;
        item.learnSpend$ = s.Fee_1 ? s.Fee_1 : 0;
        item.insure$ = s.Fee_2 ? s.Fee_2 : 0;
        item.others$ = s.Fee_3 ? s.Fee_3 : 0;
        item.eat$ = s.Fee_4;
        item.allow$ = s.Fee_5;
        item.class$ = s.Fee_6;
        item.book$ = s.Fee_7;
        item.cloth$ = s.Fee_8;
        item.travel$ = s.Fee_9;
        item.backHome$ = s.Fee_10;
        item.live$ = s.Fee_11;
        item.rowTotal = s.Subtotal;
      });
      // if (c) {
      //   const str = `server error: there are total ${c} 學系 cannot be found. Marked 暫無資料 in the below data sheet now`;
      //   this.$root.$emit("dtc-server-error", str);
      // }
      this.pageSize = bugs.length ? bugs.length : "10";
      let size = bugs.length > this.pageSize ? bugs.length / this.pageSize : 1;
      size = size > 0 ? parseInt(size) : 1;
      const mod = size > 1 && bugs.length % this.pageSize > 0 ? 1 : 0;
      this.dtcGridSize = mod + size;
      this.dtcGridSize += size === 1 && bugs.length > this.pageSize ? 1 : 0;
      this.pageRows = [...this.pageRows];
      //alert(JSON.stringify(this.pageRows[0]))
      this.rowPersonUpdate(this.pageRows[0]);
    },
    async getData() {
      this.pageRows = new Array(200).fill().map((s, t) => {
        return { myKey: t, Student: "", Remark: "" };
      });
      this.learnSpendFooter = this.insureFooter = this.othersFooter = 0;
      this.travelFooter = this.clothFooter = this.bookFooter = this.classFooter = this.allowFooter = this.eatFooter = 0;
      this.backHomeFooter = this.liveFooter = 0;
      this.sumFooter = 0;
      const {
        schoolcode: SchoolCode = "0003",
        byear: Byear = "109",
        term = "1",
        category: Category = "N",
      } = queryString.parse(location.href.split("?")[1]);
      this.term = +term;
      const url = SchoolCode
        ? `Budgeting/GetList?schoolcode=${SchoolCode}&byear=${Byear}&term=${term}&category=${Category}`
        : "Budgeting/GetList?schoolcode=0003&byear=108&term=1&category=S";
      let map = "";
      try {
        map = await window.axios.get(url);
      } catch (e) {
        const str = e + " API: " + url;
        this.$root.$emit("dtc-server-error", str);
      }

      if (Array.isArray(map)) {
        map = map[0];
      }
      if (map) {
        this.map = map;
        this.normalizeData(this.map);
      }
    },
  },
  async mounted() {
    const {
      schoolcode: SchoolCode = "0003",
      byear: Byear = "109",
      term = "1",
      category: Category = "S",
    } = queryString.parse(location.href.split("?")[1]);
    this.term = +term;
    Byear ? (this.bYear = Byear) : "";

    //for check is same school-prevent see other school
    const { schoolcode: schoolNow } = queryString.parse(
      location.href.split("?")[1]
    );

    const loginHealthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");

    const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
    const roleOtalArr = JSON.parse(sessionStorage.getItem("roleOtal"));
    const isSchoolPerson = stuChoolArr.includes(loginHealthCode) ? true : false;

    const isAdminPerson = roleOtalArr.includes(loginHealthCode) ? true : false;

    if (!isSchoolPerson && !isAdminPerson) {
      return;
    }

    if (isSchoolPerson) {
      if (loginHealthCode != schoolNow) {
        return;
      }
    }
    //check Can see and then get data
    await this.getData();
  },
  async beforeMount() {
    this.$root.$on("dtc-save-form", () => this.save());
    const schools = JSON.parse(localStorage.dtcSchoolList);
    const {
      schoolcode: SchoolCode = "0003",
      byear: Byear = "109",
      term = "1",
      category: Category = "N",
    } = queryString.parse(location.href.split("?")[1]);
    const { SchoolName } = schools.find((s) => s.SchoolCode == SchoolCode);
    this.schoolName = SchoolName;
    let arr = [];
    let url = `Major/Get?schoolcode=${SchoolCode}`;
    try {
      arr = await window.axios.get(`Major/Get?schoolcode=${SchoolCode}`);
    } catch (e) {
      const str = e + " API: " + url;
      this.$root.$emit("dtc-server-error", str);
    }
    let majors = [];
    let arr2 = [];
    arr.forEach((s) => {
      majors.push(`${s.MajorName}--${s.MajorId}`);
      arr2.push({ value: s.MajorId, text: `${s.MajorName}` });
    });
    this.majors = majors;
    this.category = arr2;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-fill-requiredxxx,
.dtc-fill-required2xxx {
  position: relative;
  &::after {
    position: absolute;
    color: red;
    content: "*";
    top: -5px;
    left: 35px;
    font-size: 24px !important;
  }
}
.dtc-fill-required2::after {
  top: 16px;
  left: 20px;
}
.dtc-print {
  --dtc-print-width: 1210px;
  position: relative;
  --dtc-border-color: var(--dark);
  margin-top: 30px;
  margin: 30px auto 2px auto;
  width: var(--dtc-print-width);
  margin-bottom: 60px;
  .input1 {
    border: none;
    max-width: 49px;
    padding: 3px;
  }
  .input2 {
    border: none;
    max-width: 89px !important;
    height: 30px;
    display: inline-block;
    padding: 3px;
  }
}

.dtc-page {
  position: absolute;
  bottom: 0;
  right: 0px;
  transform: translateY(65px);
  border: none;
}
.dtc-add {
  position: absolute;
  bottom: 0;
  right: 0px;
  transform: translateX(80px);
  border: none;
}
.dtc-title {
  position: absolute;
  top: -5px;
  left: 50%;
  display: inline-block;
  width: 1320px;
  text-align: center;
  transform: translate(-50%, -30px);
  font-size: 16px;
  font-weight: bold;
}

.dtc-print7-grid-header {
  position: relative;
  z-index: 13px;
  font-size: 12px !important;
  display: grid;
  padding: 0px;
  border: 1px solid var(--dtc-border-color);
  width: max-content;
  grid-template-columns: 50px 100px 270px 360px 250px 80px 98px;
  > div {
    height: 300px;
    border-right: 1px solid var(--dtc-border-color);
    line-height: 300px;
    text-align: center;
  }
  .sec1,
  .sec2,
  .sec3 {
    z-index: 2px;
    display: grid;
    height: 300px;
    grid-template-columns: repeat(3, 1fr);
    header {
      grid-column: 1 / -1;
      border-bottom: 1px solid var(--dtc-border-color);
      border-right: 1px solid var(--dtc-border-color);
      text-align: center;
      height: 30px;
    }
    > div {
      border-bottom: 1px solid var(--dtc-border-color);
      border-right: 1px solid var(--dtc-border-color);
      padding: 3px;
      height: 140px;
    }
    footer {
      grid-column: 1 / -1;
      height: calc(300px - 170px);
      border-right: 1px solid var(--dtc-border-color);
    }
  }
  .sec2 {
    grid-template-columns: repeat(6, 60px);
    footer {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: repeat(6, 60px);
      border: none;
      > div {
        padding: 3px;
        border-right: 1px solid var(--dtc-border-color);
        //line-height: calc(300px - 170px);
      }
    }
  }

  .sec3 {
    grid-template-columns: repeat(2, 1fr);
    footer {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      border: none;
      > div {
        padding: 3px;
        border-right: 1px solid var(--dtc-border-color);
        //line-height: calc(300px - 170px);
      }
    }
  }
}
.dtc-table,
.dtc-footer-total {
  text-align: center !important;
  position: relative;
  border-left: 1px solid var(--dtc-border-color);
  border-bottom: 1px solid var(--dtc-border-color);
  border-right: 1px solid var(--dtc-border-color);
  font-size: 12px !important;
  display: grid;
  width: var(--dtc-print-width);
  grid-template-columns: 50px 100px 270px 360px 250px 80px 98px;
  > * {
    min-height: 30px !important;
    max-height: 30px !important;
    border-right: 1px solid var(--dtc-border-color);
    > * {
      border-right: 1px solid var(--dtc-border-color);
      min-height: 30px !important;
      max-height: 30px !important;
    }
  }
}

.dtc-footer-total {
  grid-template-columns: 150px 270px 360px 250px 80px 98px;
  .dtx-count {
    text-align: center;
    line-height: 30px;
    padding-top: 0px;
  }
}

.dtc-s1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.dtc-s2 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  > div {
    padding-top: 5px;
  }
}

.dtc-s3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  > div {
    padding-top: 5px;
  }
}

.dtc-sign-area {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 38px 60px;
  > div {
    padding-top: 5px;
  }
}
.dtc-note-area {
  min-height: 200px !important;
  text-align: left;
  > div {
    border-right: none;
    padding-left: 4px;
  }
}
.text-right-mode {
  text-align: right;
  padding-right: 5px;
}

@media screen {
  .dtc-main-section {
    padding-top: 12px;
  }
}

@media print {
  @page {
    size: A4 landscape;
  }
  .dtc-print {
    --dtc-border-color: var(--dark);
  }
}
</style>
